import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <div style={{
        textAlign: "center",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        backgroundColor: "black"
      }}>
        <p style={{
          width: "100%",
          color: "white"
        }}>Loading NFTS...</p>
      </div>
    );
  }
}

export default Loader;
