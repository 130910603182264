import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Viewer from './Viewer';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

let url = process.env.REACT_APP_NFT_VIEWER_URL || "nfts.json"
let interval = process.env.REACT_APP_NFT_VIEWER_INTERVAL || 10000

root.render(
  <React.StrictMode>
    <Viewer url={url} interval={interval} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
