import React, { Component } from "react";
import Loader from './Loader';

class Viewer extends Component {
  constructor(props) {
    super(props);
    this.url = this.props.url
    this.refresh = 10

    this.state = {
      interval: this.props.interval,
      nfts: [],
      index: 0,
      progress: 0,
      loaderRefresh: this.refresh,
      imagesLoaded: false,
      nft: {}
    };

   this.getNFTs = this.getNFTs.bind(this);
   this.updateUnit = this.updateUnit.bind(this);

  };

  async getNFTs() {
    fetch(this.props.url)
      .then(response => response.json())
      .then((data) => {
        console.log("Data loaded.")
        console.log("Preloading images.")
        this.preloadImages(data)
      })
  }

  componentDidMount() {
    this.updateUnit()
    window.addEventListener("resize", this.updateUnit)
    this.getNFTs()
    .then(() => {
      this.interval = setInterval(() => {
        if(this.state.progress > 100) {
          // reset progress and update NFT
          this.setState({
            progress: 0,
            index: (this.state.index + 1) % this.state.nfts.length
          })        
        } else {
          // update progress
          this.setState(prevState => ({
            progress: prevState.progress + (100/this.props.interval*this.state.loaderRefresh)
          }));
        }
      }, this.state.loaderRefresh);
    })

  }

  updateUnit() {
    let unit = window.innerHeight > window.innerWidth ? "vw" : "vh"
    let isMobile = window.innerWidth <= 768;
    let loaderRefresh = isMobile ? 50 : 10
    this.setState({ unit: unit, mobile: isMobile, loaderRefresh: loaderRefresh })
  }

  preloadImages(data) {
    let i = 0
    data.forEach(nft => {
      new Image().src = nft.url;
      i++; 
      console.log("Preloaded", nft.name)
     if (i === data.length) {
        console.log("Images preloaded, time to display!")
        this.setState({ imagesLoaded: true, nfts: data })
     }
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.updateUnit)
  }
  
  render() {
    const { nfts, unit, mobile } = this.state
    const backgroundSize = mobile ? "25%" : "10%" 

    if (this.state.imagesLoaded) {
      const viewerStyles = {
        container: {
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
        background: {
          textAlign: "center",
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          bottom: 0,
          backgroundColor: "black",
          backgroundSize: backgroundSize,
          backgroundRepeat: "repeat",
          filter: "blur(10px) brightness(80%)",
          backgroundImage: `url(${nfts[this.state.index].url})`,
        },
        polaroid: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: `72${unit}`,
          height: `80${unit}`,
          filter: "drop-shadow(16px 16px 10px #333)",
          padding:`2${unit}`,
          backgroundImage: "url(/paper-texture.png)"
        },
        picture: {
          flex: 9,
          backgroundImage: `url('${nfts[this.state.index].url}')`,
          backgroundSize: "cover",
          backgroundColor: "black",
          backgroundPosition: "center center",
          aspectRatio: "1/1",
          width: `72${unit}`,
        },
        titleContainer: {
          flex: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: `2${unit}`
        },
        title: {
          margin: 0,
          fontFamily: "Nanum Pen Script, cursive",
          fontSize: `4${unit}`,
          fontWeight: "normal",
          textAlign: "center",
          rotate: "-3deg",
        },
        progressBar: {
          width: `${this.state.progress}%`,
          position: "absolute",
          bottom: "0",
          left: "0",
          height: "0.5vh",
          backgroundColor: "orange",
        }
      };

      return (
        <div style={viewerStyles.container}>
          <div style={viewerStyles.background}></div>
          <div style={viewerStyles.polaroid}>
            <div style={viewerStyles.picture}></div>
            <div style={viewerStyles.titleContainer}>
              <h1 style={viewerStyles.title}>{ nfts[this.state.index].name }</h1>
            </div>
          </div>
          <div style={viewerStyles.progressBar} />
        </div>
      );

    } else {
      return(<Loader />)
    }

  }
}

export default Viewer;
